@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full font-site-base tracking-wide text-site-base;
    word-spacing: -3px;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .title--normal {
    @apply title--base font-light text-3xl lg:text-6xl uppercase;
  }

  .title--small {
    @apply title--base text-lg font-semibold flex items-center before:content-[''] before:bg-[url('/icons/Vector.svg')] before:bg-no-repeat before:inline-flex before:w-[21px] before:h-[20px] before:mr-3;
  }

  .btn {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-accent-400 hover:bg-site-accent-600 bg-site-accent text-white;
  }

  .btn--accent {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-accent-400 hover:bg-site-accent-600 bg-site-accent text-white;
  }

  .btn--conversion {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-conversion-400 hover:bg-site-conversion-600 bg-site-conversion text-white;
  }

  .btn--base {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-base-400 hover:bg-site-base-600 bg-site-base text-white;
  }

  .btn--white {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-base hover:bg-site-base hover:text-white bg-white text-site-base;
  }

  .btn--base-outlined {
    @apply btn--basis !px-6 btn--pseudo border-transparent focus:bg-site-base-400 hover:bg-site-base-600 border-site-base bg-transparent hover:text-white text-site-base;
  }

  .btn--white-outlined {
    @apply btn--basis !px-6 btn--pseudo border-transparent hover:bg-white border-white bg-transparent hover:text-site-base text-white;
  }

  .btn--alt {
    @apply btn--basis btn--pseudo bg-transparent text-site-base  border-site-base [&:not([disabled])]:focus:bg-site-base [&:not([disabled])]:focus:text-white hover:bg-site-base hover:text-white;
  }

  /* Margins for sections without a background */
  .section--mt {
    @apply mt-14 lg:mt-32;
  }

  .section--mb {
    @apply mb-14 lg:mb-32;
  }

  /* Paddings for sections with a background */
  .section--pt {
    @apply pt-14 lg:pt-32;
  }

  .section--pb {
    @apply pb-14 lg:pb-32;
  }

  .section:not(.section--with-bg) {
    @apply section--mt section--mb;
  }

  .section.section--with-bg {
    @apply section--pt section--pb;
  }

  .section.section--with-bg.bg-site-grey + .section.section--with-bg.bg-site-grey,
  .bg-site-grey + .bg-site-grey {
    @apply pt-0;
  }

  .remove-section > .section,
  .remove-section > .section--with-bg,
  .remove-section > .section > .container,
  .remove-section .container {
    @apply !p-0 !m-0;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--lists content--links content--headings;
  }

  .content p + p {
    @apply mt-4;
  }

  .containerize {
    @apply max-w-[1920px] mx-auto;
  }
}

@layer utilities {
  .title--base {
    @apply text-left text-site-base mb-4;
  }

  .btn--pseudo {
    @apply transition-colors disabled:opacity-75;
  }

  .btn--basis {
    @apply py-2 px-10 border-[2px] rounded-xl inline-flex font-semibold text-base gap-3 lg:gap-8 items-center justify-center;
  }

  .btn--after {
    @apply after:content-[''] after:bg-[url('/icons/pijl.svg')] after:bg-no-repeat after:inline-flex after:w-[38px] after:h-[21px] after:ml-4;
  }

  .btn--alt--after {
    @apply after:content-[''] after:bg-[url('/icons/pijl-alt.svg')] after:bg-no-repeat after:inline-flex after:w-[38px] after:h-[21px] after:ml-4;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-site-accent;
  }

  .content--lists {
    @apply children-ol:list-decimal children-ul:list-disc children-ol:ml-4 children-ul:ml-4 children-ol:mb-4 children-ul:mb-4 children-li:font-normal children-li:text-base sm:children-li:text-lg children-li:text-site-base;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content--links {
    @apply children-ul:list-disc children-ul:pl-7 children-ul:mb-6 children-ul:font-normal children-ul:text-site-base;
    @apply children-ol:list-decimal children-ol:pl-7 children-ol:mb-6 children-ol:font-normal children-ol:text-site-base;
  }

  .content--paragraphs {
    @apply children-p:!leading-6 sm:children-p:!leading-[30px] children-p:mb-4 children-p:font-normal children-p:text-base sm:children-p:text-lg children-p:text-site-base;
    @apply children-blockquote:px-6 lg:children-blockquote:px-20 lg:children-blockquote:py-10;
  }

  .content--headings {
    @apply children-headings:mb-5 children-h1:mb-5 children-h1:text-3xl children-headings:font-semibold children-headings:text-site-base;
    @apply children-h2:text-xl md:children-h2:text-2xl lg:children-h2:text-3xl;
    @apply children-h3:text-lg md:children-h3:text-xl lg:children-h3:text-2xl;
  }
}

/* Pagination */

a.pagination--item,
.pagination--item > a,
.pagination--button > a {
  @apply h-[32px] xs:h-[38px] no-underline max-xs:text-sm flex items-center justify-center bg-white border border-site-grey text-site-base/40 font-semibold rounded-none btn--accent;
}

a.pagination--item,
.pagination--item > a {
  @apply w-[30px] xs:w-[40px];
}

.pagination--button > a {
  @apply px-2 xs:px-4;
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  /* @apply hidden; */
}

#nprogress .spinner-icon {
  @apply !border-l-site-base border-[3px] !border-t-site-base !border-b-site-accent !border-r-site-accent;
}

#nprogress .peg {
  @apply shadow;
}

/* .swiper-button-disabled {
  @apply opacity-40 pointer-events-none;
} */

.losse-kruumel-history {
  @apply flex gap-1 items-center;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn btn--after;
}

.form-duuf-group {
  /* @apply; */
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex-center;
}

.form-duuf-label {
  @apply block text-base font-semibold text-site-base mb-2;
}

.form-duuf-group-name-first,
.form-duuf-group-name-last {
  @apply w-full;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-[4px] h-12 border-site-grey-700 w-full px-5 py-5 border bg-site-grey text-site-base;
}

.form-duuf-group-consent span {
  @apply pl-3 leading-[1] text-sm;
}
.form-duuf-group-consent span a {
  @apply leading-[1] text-sm text-site-conversion underline hover:text-site-conversion-600;
}

.form-duuf-error {
  @apply text-red-400 text-sm;
}

.form-duuf-textarea {
  @apply h-[160px];
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply hover:bg-site-base transition-all duration-200 hover:text-white flex items-center bg-site-grey rounded-[4px] py-[10px] px-5;
}

.form-duuf-file-button button:after {
  @apply content-[''] transition-all duration-200 hover:bg-[url('/icons/upload-hover.svg')] bg-[url('/icons/upload.svg')] bg-no-repeat inline-flex w-[24px] h-[24px] ml-4;
}

.form-duuf-file-name {
  @apply pl-3 font-light;
}

/* Custom */
.select-filter__option {
  @apply text-site-base;
}

.swiper-button-prev {
  @apply after:hidden;
}

.swiper-slide {
  @apply !overflow-visible;
}

.three-visible .swiper-slide {
  @apply opacity-0 duration-200 transition-all;
}

.three-visible .swiper-slide-active {
  @apply opacity-100;
}

.three-visible .swiper-slide-next,
.three-visible .swiper-slide-prev {
  @apply opacity-35;
}
